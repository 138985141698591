<template>
  <section class="container my-4">
    <h1>WHS Soccer Schedule</h1>
    <p class="lead">Unfortunately, due to COVID, we did not have enough interest to field a team for this upcoming season. If you or anyone you know would like to play in the future, we need at least 15 players to field a roster. Hopefully we can return to the pitch with you soon!</p>
    <div class="my-4 py-4">
      <h2>Interested in sports at Wellsville High School?</h2>
      <p>Visit the official website and master schedule of sports at Wellsville High School (OH) via the link below. </p>
      <a href="https://www.wellsville.k12.oh.us/athletics" target="_blank" rel="noopener" class="btn btn-dark">Wellsville Tigers Schedule</a>
    </div>
    <Spacer />
  </section>
</template>

<script>
import Spacer from '@/components/Spacer.vue';

export default {
    name: 'Home',
    components: {
        Spacer
    },
    metaInfo: {
    title: "Schedule | Wellsville Soccer - WHS Tiger Athletics in Wellsville, Ohio",
    meta: [
      { name: "author", content: "Github: @TheKicker" },
      {
        name: "description",
        content:
          "Unfortunately, due to COVID, we did not have enough interest to field a team for this upcoming season. If you or anyone you know would like to play in the future, we need at least 15 players to field a roster.",
      },
      {
        name: "keywords",
        content:
          "Wellsville, Ohio, WHS, Wellsville Tigers, OH, High School, HS, Tigers, Athletics, Soccer, Futbol, Football, Boys, Girls, Co-ed, OHSAA, OVAC, EOAC",
      },
      // OpenGraph data (Most widely used)
      { property: "og:title", content: "Schedule | Wellsville Soccer - WHS Tiger Athletics in Wellsville, Ohio" },
      { property: "og:site_name", content: "Schedule | Wellsville Soccer - WHS Tiger Athletics in Wellsville, Ohio" },
      // The list of types is available here: http://ogp.me/#types
      { property: "og:type", content: "website" },
      // Should the the same as your canonical link, see below.
      {
        property: "og:url",
        content: "https://wellsvillesoccer.com/schedule",
      },
      {
        property: "og:image",
        content: "https://wellsvillesoccer.com/wellsville-soccer-ogp.png",
      },
      // Often the same as your meta description, but not always.
      {
        property: "og:description",
        content:
          "Unfortunately, due to COVID, we did not have enough interest to field a team for this upcoming season. If you or anyone you know would like to play in the future, we need at least 15 players to field a roster.",
      },
      // Twitter card
      { name: "twitter:card", content: "summary" },
      {
        name: "twitter:site",
        content: "https://wellsvillesoccer.com/schedule",
      },
      { name: "twitter:title", content: "Wellsville Soccer - WHS Tiger Athletics in Wellsville, Ohio" },
      {
        name: "twitter:description",
        content:
          "Unfortunately, due to COVID, we did not have enough interest to field a team for this upcoming season. If you or anyone you know would like to play in the future, we need at least 15 players to field a roster.",
      },
      // Your twitter handle, if you have one.
      { name: "twitter:creator", content: "Github: @TheKicker" },
      {
        name: "twitter:image:src",
        content: "https://wellsvillesoccer.com/wellsville-soccer-ogp.png",
      },
      // Google / Schema.org markup:
      { itemprop: "name", content: "Schedule | Wellsville Soccer - WHS Tiger Athletics in Wellsville, Ohio" },
      {
        itemprop: "description",
        content:
          "Unfortunately, due to COVID, we did not have enough interest to field a team for this upcoming season. If you or anyone you know would like to play in the future, we need at least 15 players to field a roster.",
      },
      {
        itemprop: "image",
        content: "https://wellsvillesoccer.com/wellsville-soccer-ogp.png",
      },
    ],
  }
}
</script>

<style>

</style>